import Iframe from 'react-iframe';
import React from 'react';
import { cx } from 'emotion';
import { Colors, ProgressIndicator } from '@calico/calico-ui-kit';

const CXG_URL = 'http://calico-website-mca-cxg.calicolabs.com/';

const CxgPage = ({ className, style }) => {
  return (
    <div className={cx('relative full-height full-width', className)} style={style}>
      <div className="full-height full-width fill">
        <div className="content-width">
          <h3 className="grey-l normal padding-top-5">Loading embeddings (this may take a moment)</h3>
          <ProgressIndicator block className="font-size-7 grey-xl padding-bottom-6" dots />
        </div>
      </div>
      <div style={{ borderTop: `1px solid ${Colors['grey-xxl']}` }}>
        <Iframe
          height="100vh"
          styles={{ paddingLeft: 15, paddingRight: 15 }}
          url={CXG_URL}
        />
      </div>
    </div>
  );
}

export default CxgPage;
