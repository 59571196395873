import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Colors } from '@calico/calico-ui-kit';
import {
  AuthorList,
  CalicoResearchFooter,
  CalicoResearchHeader,
  Citation,
  Navigation,
  ShareButtons,
  Title,
} from '@calico/calico-ui-editorial';

import Authors from './content/Authors';
import CxgPage from './pages/CxgPage';
import DownloadsPage from './pages/DownloadsPage';
import HomePage from './pages/HomePage';
import SoftwarePage from './pages/SoftwarePage';
import withAnalytics from './components/withAnalytics';

const withAnalyticsCxgPage = withAnalytics(CxgPage);
const withAnalyticsDownloadsPage = withAnalytics(DownloadsPage);
const withAnalyticsHomePage = withAnalytics(HomePage);
const withAnalyticsSoftwarePage = withAnalytics(SoftwarePage);

const CITATION = 'Murine single-cell RNA-seq reveals cell-identity- and tissue-specific trajectories of aging. Jacob C Kimmel, Lolita Penland, Nimrod D Rubinstein, David G Hendrickson, David R Kelley*, Adam Z Rosenthal*. Genome Research, 2019; doi: 10.1101/gr.253880.119';
const DOI = 'doi:10.1101/gr.253880.119';
const CITATION_TITLE = 'Murine single-cell RNA-seq reveals cell-identity- and tissue-specific trajectories of aging';
const RESEARCH_TITLE = 'Murine aging cell atlas';
const WEBSITE_URL = 'http://mca.research.calicolabs.com';

class App extends React.Component {
  state = {
    calicoResearchHeaderBottom: 0,
    scrollTop: 0,
    titleBottom: 0,
  };

  updateScroll = (event: Object) => {
    this.setState({ scrollTop: event.target.scrollTop });
  }

  render () {
    const { calicoResearchHeaderBottom, scrollTop, titleBottom } = this.state;
    return (
      <DocumentTitle title={`${RESEARCH_TITLE} | Calico research`}>
        <div>
          <Router basename={process.env.PUBLIC_URL}>
            <div
              className="relative app flex-column"
              id="app"
              onScroll={this.updateScroll}
              style={{
                height: '100vh',
                overflowX: 'hidden',
                overflowY: 'scroll',
              }}
            >
              <CalicoResearchHeader
                onMeasureElement={rect => this.setState({ calicoResearchHeaderBottom: rect.bounds.bottom })}
                subheader={RESEARCH_TITLE}
                showSubheader={scrollTop > titleBottom - calicoResearchHeaderBottom}
              />
              <Title
                onMeasureElement={rect => this.setState({ titleBottom: rect.bounds.bottom })}
                scrollTop={scrollTop}
                title={RESEARCH_TITLE}
              />
              <Navigation
                className="content-width"
                items={[
                  {
                    name: 'Home',
                    path: '/'
                  },
                  {
                    name: 'Interactive Embeddings',
                    path: '/embeddings'
                  },
                  {
                    name: 'Downloads',
                    path: '/data'
                  },
                  {
                    name: 'Software',
                    path: '/software'
                  }
                ]}
                style={{
                  backgroundColor: scrollTop > titleBottom && Colors['grey-xxxl'],
                  top: calicoResearchHeaderBottom,
                }}
              />

              <Route
                exact
                path="/"
                render={() => (
                  <section className="content-width font-size-small smooth">
                    <Citation
                      citation={CITATION}
                      className="margin-bottom-2 bold"
                      uri={DOI}
                      title={CITATION_TITLE}
                      showCopyUri
                      showCopyCitation
                      style={{ lineHeight: 1.35 }}
                    />
                    <AuthorList
                      authors={Authors}
                      showContact
                      contactSubject={RESEARCH_TITLE}
                    />
                  </section>
                )}
              />
              {
              /*
               * Temporarily removed for p-counsel review.
               *
              <Route
                exact
                path="/"
                render={() => (
                  <ShareButtons
                    className="home-share-buttons"
                    style={{ top: titleBottom }}
                    title={RESEARCH_TITLE}
                    url={WEBSITE_URL}
                  />
                )}
              />
               *
               */
              }

              <section className="flex-1" style={{ minHeight: '150vh' }}>
                <Switch>
                  <Route exact path="/" component={withAnalyticsHomePage} />
                  <Route exact path="/embeddings" component={withAnalyticsCxgPage} />
                  <Route exact path="/data" component={withAnalyticsDownloadsPage} />
                  <Route exact path="/software" component={withAnalyticsSoftwarePage} />
                </Switch>
                <CalicoResearchFooter>
                  {
                  /*
                   * Temporarily removed for p-counsel review.
                   *
                  <ShareButtons
                    title={RESEARCH_TITLE}
                    url={WEBSITE_URL}
                  />
                   *
                   */
                  }
                </CalicoResearchFooter>
              </section>
            </div>
          </Router>
        </div>
      </DocumentTitle>
    );
  }
}

export default App;
