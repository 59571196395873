import React from 'react';
import { Bullets } from '@calico/calico-ui-editorial';
import { LinkExternal } from '@calico/calico-ui-kit';

const BASE_URL = 'https://storage.googleapis.com/calico-website-mca-storage';
const META_DES = "Inferred cell types ('cell_type'), states ('subtype'), ages ('age'), and animal of origin ('animal') are available as row annotations. Note that 'animal' is not a unique identifier. To identify unique animals, use the combination of 'age' and 'animal'."

export default [
  {
    format: 'h5ad',
    name: 'Kidney single cell count data (filtered)',
    size: '76 MB',
    src: `${BASE_URL}/kidney.h5ad`,
    title: 'Kidney single cell count data (filtered)',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Genes expressed in few cells or replicates have been removed from this object, representing the matrix we use for downstream analysis.</p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>kidney.h5ad</strong>,
              content: 'Single cell RNA count data for kidney.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'h5ad',
    name: 'Kidney single cell count data (unfiltered)',
    size: '86 MB',
    src: `${BASE_URL}/kidney_unfiltered.h5ad`,
    title: 'Kidney single cell count data (unfiltered)',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        All detected genes are present in this object.</p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>kidney_unfiltered.h5ad</strong>,
              content: 'Single cell RNA count data for kidney.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'h5ad',
    name: 'Lung single cell count data (filtered)',
    size: '198 MB',
    src: `${BASE_URL}/lung.h5ad`,
    title: 'Lung single cell count data (filtered)',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Genes expressed in few cells or replicates have been removed from this object, representing the matrix we use for downstream analysis.</p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>lung.h5ad</strong>,
              content: 'Single cell RNA count data for lung.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'h5ad',
    name: 'Lung single cell count data (unfiltered)',
    size: '216 MB',
    src: `${BASE_URL}/lung_unfiltered.h5ad`,
    title: 'Lung single cell count data (unfiltered)',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        All detected genes are present in this object.</p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>lung_unfiltered.h5ad</strong>,
              content: 'Single cell RNA count data for lung.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'h5ad',
    name: 'Spleen single cell count data (filtered)',
    size: '206 MB',
    src: `${BASE_URL}/spleen.h5ad`,
    title: 'Spleen single cell count data (filtered)',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Genes expressed in few cells or replicates have been removed from this object, representing the matrix we use for downstream analysis.</p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>spleen.h5ad</strong>,
              content: 'Single cell RNA count data for spleen.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'h5ad',
    name: 'Spleen single cell count data (unfiltered)',
    size: '222 MB',
    src: `${BASE_URL}/spleen_unfiltered.h5ad`,
    title: 'Spleen single cell count data (unfiltered)',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        All detected genes are present in this object.</p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>spleen_unfiltered.h5ad</strong>,
              content: 'Single cell RNA count data for spleen.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
  {
    format: 'tar.gz',
    name: 'scNym cell type classification model weights',
    size: '508 MB',
    src: `${BASE_URL}/scnym_weights.tar.gz`,
    title: 'scNym model weights',
    content: (
      <div className="max-line-length">
        <p>We used <a href="https://github.com/calico/scnym/">scNym</a> models
        to classify cell types and states in our data. Here, we provide pre-trained weights
        for these models.</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>tissue_independent.pkl</strong>,
              content: 'Cell type classification model weights trained on the Tabula Muris 10X data across all tissues.',
            },
            {
              title: <strong>kidney.pkl</strong>,
              content: 'Cell type classification model weights trained on Tabula Muris 10X data for kidney only.',
            },
            {
              title: <strong>lung.pkl</strong>,
              content: 'Cell type classification model weights trained on Tabula Muris 10X data for lung only.',
            },
            {
              title: <strong>spleen.pkl</strong>,
              content: 'Cell type classification model weights trained on Tabula Muris 10X data for spleen only.',
            },
            {
              title: <strong>kidney_subtype.pkl</strong>,
              content: 'Cell state classification model weights trained on manually annotated Tabula Muris 10X data for kidney only.',
            },
            {
              title: <strong>lung_subtype.pkl</strong>,
              content: 'Cell state classification model weights trained on manually annotated Tabula Muris 10X data for lung only.',
            },
            {
              title: <strong>spleen_subtype.pkl</strong>,
              content: 'Cell state classification model weights trained on manually annotated Tabula Muris 10X data for spleen only.',
            },
            {
              title: <strong>config.txt</strong>,
              content: 'A model configuration file matching the models used in our manuscript.'
            },
            {
              title: <strong>tm_10x_all_gene_names.txt</strong>,
              content: 'Genes used for model training.'
            },
            {
              title: <strong>tm_10x_metadata.txt</strong>,
              content: 'Metadata describing cell type output classes for scNym models.'
            },
            {
              title: <strong>tm_10x_subtype_metadata.txt</strong>,
              content: 'Metadata describing cell state output classes for scNym models.'
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
];
