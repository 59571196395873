export default [
  {
    nameFirst: 'Jacob C.',
    nameLast: 'Kimmel',
    email: 'jacob@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Lolita',
    nameLast: 'Penland',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Nimrod D.',
    nameLast: 'Rubinstein',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'David G.',
    nameLast: 'Hendrickson',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'David R.',
    nameLast: 'Kelley',
    email: 'drk@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },
  {
    nameFirst: 'Adam Z.',
    nameLast: 'Rosenthal',
    email: 'adam.rosenthal@dupont.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },
];
